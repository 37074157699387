<template>
  <div class="seller__post">
    <div class="messages__box">
      <div class="messages__avatar">
        <img v-if="message.user.avatar" :src="message.user.avatar" alt="">
        <img v-else src="@/assets/images/avatar.svg" alt="">
      </div>
      <div class="messages-content">
        <div class="messages-content__view">
          <div class="messages-content__head">
            <div class="messages-content__head-left">
              <div class="messages-content__author">
                {{ message.user.name }}
                ({{ message.user.role === 'admin' ? 'weTHINK admin' : message.user.role }})
              </div>
              <div class="messages-content__time">{{ message.created_at }}</div>
            </div>
          </div>
          <div class="messages-content__text">
            {{ message.text }}
          </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OfferMessageFromBuyer',
  props: {
    message: {
      type: Object,
      required: true,
    },
    sellerEmail: {
      type: String,
      required: true,
    },
  },
};
</script>
