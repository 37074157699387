import axios from '@/libs/axios';

export default {
  /**
  * Send request for get marketplaces
  * @param {Object} data
  * @returns {Object}
  */
  async marketplaces() {
    const res = await axios.get('/marketplaces');
    return res;
  },
  /**
  * Send request for get marketplace
  * @param {Object} data
  * @returns {Object}
  */
  async marketplace(id) {
    const res = await axios.get(`/marketplaces/${id}`);
    return res;
  },
  /**
  * Send request for get marketplace projects
  * @param {Object} data
  * @returns {Object}
  */
  async marketplaceProjects(id = 1, isBuyer = true) {
    let res = null;
    if (id === -100) {
      res = await axios.get(`/marketplace-projects?per-page=1000&user=${isBuyer ? 'buyer' : 'seller'}`);
      return res;
    }
    res = await axios.get(`/marketplace-projects?per-page=1000&marketplace_id=${id}&user=${isBuyer ? 'buyer' : 'seller'}`);
    return res;
  },
  async createMarketplaceProject(data) {
    const res = await axios.post('/marketplace-projects', data);
    return res;
  },
  async getMarketplaceProject(projectSlug, isBuyer = true) {
    const res = await axios.get(`/marketplace-projects/${projectSlug}?user=${isBuyer ? 'buyer' : 'seller'}`);
    return res;
  },
  async updateMarketplaceProject(id, data) {
    const res = await axios.put(`/marketplace-projects/${id}`, data);
    return res;
  },
  async deleteMarketplaceProject(id) {
    const res = await axios.delete(`/marketplace-projects/${id}`);
    return res;
  },
  async getAllMarketplaceOffers(isBuyer = true, query = {}) {
    const res = await axios.get(`/marketplace-offers?user=${isBuyer ? 'buyer' : 'seller'}`, {
      params: query,
    });
    return res;
  },
  async projectOffers(projectId, isBuyer = true) {
    const res = await axios.get(`/marketplace-offers?project_id=${projectId}&user=${isBuyer ? 'buyer' : 'seller'}`);
    return res;
  },
  async createOffer(data) {
    const res = await axios.post('/marketplace-offers', data);
    return res;
  },
  async getOffer(offerId) {
    const res = await axios.get(`/marketplace-offers/${offerId}`);
    return res;
  },
  async updateOffer(offerId, data) {
    const res = await axios.put(`/marketplace-offers/${offerId}`, data);
    return res;
  },
  async deleteOffer(offerId) {
    const res = await axios.delete(`/marketplace-offers/${offerId}`);
    return res;
  },
  async marketplacesStatistics(isBuyer = true, query = {}) {
    const res = await axios.get(`/marketplaces/user?user=${isBuyer ? 'buyer' : 'seller'}`, {
      params: query,
    });
    return res;
  },
  async marketplaceOfferMessage(data) {
    const res = await axios.post('/marketplace-offers/message', data);
    return res;
  },
  async changeOfferStatus(payload) {
    console.log(payload, 'payload');
    const res = await axios.put('/marketplace-offers/status', payload);
    return res;
  },
  async uploadFile(id, data) {
    const res = await axios.post(`/media/marketplace_project/${id}`, data);
    return res;
  },
  async removeFile(id, data) {
    const res = await axios.delete(`/media/marketplace_project/${id}`, {
      data,
    });
    return res;
  },
  async offerUploadFile(id, data) {
    const res = await axios.post(`/media/marketplace_offer/${id}`, data);
    return res;
  },
  async offerRemoveFile(id, data) {
    const res = await axios.delete(`/media/marketplace_offer/${id}`, {
      data,
    });
    return res;
  },
  async sellerSaveProject(data) {
    const res = await axios.post('/marketplaces/user', data);
    return res;
  },
  async sellerUnsaveProject(data) {
    const res = await axios.delete('/marketplaces/user', {
      data,
    });
    return res;
  },
};
