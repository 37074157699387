<template>
  <div>
    <div class="header popup-offer" id="header-popup-offer">
      <div class="make-offer-header">
        <h1>Offer Detail</h1>
        <div class="make-offer-action">
          <button class="btn btn-secondary"
            :disabled="isLoading || confirmDelete"
            @click="closeModal()">Cancel
          </button>
          <button type="submit"
            class="btn btn-secondary"
            @click="filterFormData(6)"
            :disabled="isLoading || confirmDelete">
            Save as Draft
          </button>
          <button class="btn btn-secondary"
            :disabled="confirmDelete"
            @click="showConfirmationDelete">
            Delete
          </button>
          <button
            v-if="me.safe_seller"
            type="submit"
            class="btn btn-secondary"
            @click="filterFormData(5)"
            :disabled="isLoading || confirmDelete">
            Publish
          </button>
        </div>
      </div>
    </div>
    <div class="error-wrapper">
      <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    </div>
    <p class="draft-note" v-if="!me.safe_seller">
      Please <a class="link" href="mailto:info@wethink.eu">
      contact us</a> in order to make your Offers visible to Buyers!
    </p>
    <div class="confirmation" v-if="confirmDelete">
      <h4>Are you sure you want to delete this Offer?</h4>
      <div class="confirmation-actions">
        <a href="" @click.prevent="deleteOffer(true)">Yes</a>
        <a href="" @click.prevent="deleteOffer(false)">No</a>
      </div>
    </div>
    <section class="make-offer-content">
      <ValidationObserver slim>
        <form class="mb-4">
          <div class="form-col form-col-2">
            <div class="form-line">
                <label>Price, euro:</label>
                <input class="form-control" type="number" min="0" placeholder="0.000"
                regex="^\d+(?:\.\d{1,2})?$"
                v-model="form.price">
            </div>
            <div class="form-line">
                <label class="required">Delivery date:</label>
                <DatePicker
                  class="form-control"
                  style="margin-bottom: 0.5rem;"
                  v-model="form.delivery_at"
                  :disabled-date="disabledDate"
                  format="DD.MM.YYYY"
                  placeholder="DD.MM.YYYY"
                />
            </div>
          </div>
          <div class="form-col-1 mb-2">
            <label class="required">Company details seller:</label>
            <textarea v-model="form.company_detail"
            class="form-textarea" cols="30" rows="5" placeholder=""/>
          </div>
          <div class="form-col-1 mb-2">
            <label class="required">Scope of work:</label>
            <textarea v-model="form.scope_work"
            class="form-textarea" cols="30" rows="5" placeholder=""/>
          </div>
          <div class="addmedia">
            <div class="addmedia__downloaded">
              <h5 class="addmedia__downloaded-title">Presentation and documentation</h5>
              <div v-if="docs.length">
                <h6>Documents ({{docs.length}}):</h6>
                <ul >
                  <li class="addmedia__downloaded-item" v-for="item in docs" :key="item.url">
                    <span class="icon-pdf"></span>
                    <span class="addmedia__downloaded-name">
                      {{ item.title }}
                    </span>
                    <span class="icon-delete pointer" @click="onRemoveFile(item)"></span>
                  </li>
                </ul>
              </div>
              <div v-if="images.length">
                <h6>Images ({{images.length}}):</h6>
                <ul class="media-list">
                  <li class="media-item" v-for="item in images" :key="item.url">
                    <span class="icon-delete pointer" @click="onRemoveFile(item)"></span>
                    <img :src="item.url" alt="">
                    <span>{{ item.title }}</span>
                  </li>
                </ul>
              </div>
              <div v-if="videos.length">
                <h6>Video ({{videos.length}}):</h6>
                <ul class="media-list">
                  <li class="media-item" v-for="item in videos" :key="item.thumbnail">
                    <span class="icon-delete pointer" @click="onRemoveFile(item)"></span>
                    <div v-html="item.html"></div>
                    <span>{{ item.name }}</span>
                  </li>
                </ul>
              </div>
              <div class="addmedia__title">
            Add Media:
            </div>
            <ul class="addmedia__list">
              <li>
                <a href=""><i class="icon-file"></i></a>
              </li>
              <li>
                <a href=""><i class="icon-youtube"></i></a>
              </li>
              <li>
                <a href=""><i class="icon-image"></i></a>
              </li>
            </ul>
              <div class="addmedia__wrapper">
                <h5 class="addmedia__wrapper-title">Add a Video</h5>
                <span class="addmedia__wrapper-notice">Here you can insert a URL from
                  a video platform (for example YouTube). The video will then be embedded.
                </span>
                <input
                  v-model="selectedVideo"
                  type="text"
                  name="url"
                  pattern="^https?://.+$"
                  placeholder="Insert URL"
                  class="form-control">
                <button class="btn btn-primary"
                  :disabled="isLoading || !selectedVideo"
                  @click="uploadFile('video')">
                  Update video
                </button>
              </div>
              <div class="addmedia__wrapper">
                <h5 class="addmedia__wrapper-title">Add a File</h5>
                <span class="addmedia__wrapper-notice">Here you can upload various files
                  to be displayed in a media carousel. You are able to upload PDFs,
                  images, or gifs.
                </span>
                <div v-if="this.selectedFile">
                  {{ this.selectedFile.name }}
                  <span class="icon-delete pointer" @click="removeSelectedFile"></span>
                </div>
                <div class="file-drop">
                  <div class="file-drop__body">
                    <input type="file"
                      @change="onChangeFile"
                      ref="inputFiles"
                      accept="application/pdf, video/*, image/*">
                    <img src="@/assets/images/upload.png" alt="" class="file-drop__icon">
                    <div class="file-drop__info">
                      Formats: PDF, image<br>
                      Max: 5Mb
                    </div>
                  </div>
                </div>
                <button
                  class="btn btn-primary"
                  :disabled="isLoading || !selectedFile"
                  @click="uploadFile('document')">
                  Update file
                </button>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </section>
    <div class="discussion">
      <div class="messages__wapper"
      v-for="message in messages" :key="message.id">
      <OfferMessage
      :message="message"
      :sellerEmail="offer.user.email"
      />
      </div>
    </div>
    <div v-if="!isNewOffer"
      class="discussion__body">
      <div class="discussion__comment">
        <div class="discussion__avatar">
          <img v-if="me.avatar" :src="me.avatar" alt="">
          <img v-else src="@/assets/images/avatar.svg" alt="">
        </div>
        <div class="discussion__form">
          <textarea class="growTextarea" name="" row="1" id="" placeholder="Comment..."
          style="height: 54px;" v-model="message"></textarea>
          <button class="discussion__btn" @click="sendMessage($event)">
            Submit
          </button>
        </div>
      </div>
    </div>
    <p class="draft-note mt-2" v-if="!me.safe_seller">
      Please <a class="link" href="mailto:info@wethink.eu">
      contact us</a> in order to make your Offers visible to Buyers!
    </p>
    <div class="error-wrapper">
      <Alert :variant="variant" v-if="text">{{ text }}</Alert>
    </div>
    <div class="make-offer-bottom">
      <div class="make-offer-action">
        <button class="btn btn-secondary"
          :disabled="isLoading || confirmDelete"
          @click="closeModal()">Cancel
        </button>
        <button type="submit"
          class="btn btn-secondary"
          @click="filterFormData(6)"
          :disabled="isLoading || confirmDelete">
          Save as Draft
        </button>
        <button class="btn btn-secondary"
          :disabled="confirmDelete"
          @click="showConfirmationDelete">
          Delete
        </button>
        <button
          v-if="me.safe_seller"
          type="submit"
          class="btn btn-secondary"
          @click="filterFormData(5)"
          :disabled="isLoading || confirmDelete">
          Publish
        </button>
      </div>
    </div>
  </div>
</template>
<script>

import exception from '@/mixins/exception';
import alert from '@/mixins/alert';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import MatchmakingService from '@/services/MatchmakingService';
import moment from 'moment';
import Alert from '@/components/Alert.vue';
import OfferMessage from '@/views/profile/Matchmaking/Seller/OfferMessage.vue';
import DatePicker from 'vue2-datepicker';

export default {
  name: 'FormRequest',
  mixins: [exception, alert],
  data() {
    return {
      form: {
        id: this.formId,
        marketplace_id: this.marketplaceId,
        project_id: this.projectId,
        delivery_at: '',
        price: 0,
        company_detail: '',
        scope_work: '',
        visible: 1,
        status: 6, // draft
      },
      messages: [],
      message: '',
      isLoading: false,
      selectedVideo: '',
      selectedFile: null,
      docs: [],
      videos: [],
      images: [],
      confirmDelete: false,
    };
  },
  components: {
    Alert, OfferMessage, DatePicker,
  },
  props: {
    marketplaceId: {
      type: Number,
      required: true,
    },
    formId: {
      type: String,
      required: true,
    },
    isNewOffer: {
      type: Boolean,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    offer: {
      type: Object,
      required: false,
    },
  },
  watch: {
    formId() {
      this.clearForm();
      this.form.id = this.formId;
    },
  },
  onUnmounted() {
    this.clearForm();
  },
  computed: {
    me() {
      return this.$store.getters.getMe;
    },
  },
  methods: {
    disabledDate(date) {
      return date < new Date();
    },
    async sendMessage(event) {
      event.preventDefault();
      event.stopPropagation();
      if (this.message) {
        try {
          const payload = {
            offer_id: this.offer.id,
            text: this.message,
          };
          const { data } = await MatchmakingService.marketplaceOfferMessage(payload);
          this.messages.push(data);
        } catch (error) {
          this.handleException(error);
        } finally {
          this.message = '';
        }
      }
    },
    removeSelectedFile() {
      this.selectedFile = null;
      this.$refs.inputFiles.value = '';
    },
    deleteOffer(predicate) {
      if (predicate) {
        if (!this.isNewOffer) {
          this.$emit('onDeleteOffer', this.offer.id);
        }
        this.closeModal();
      }
      this.confirmDelete = false;
    },
    showConfirmationDelete() {
      this.confirmDelete = !this.confirmDelete;
      const modalRequestOffer = document.getElementById('header-popup-offer');
      if (modalRequestOffer) {
        modalRequestOffer.scrollIntoView({ behavior: 'smooth' });
      }
    },
    onChangeFile(event) {
      // check max file size 10MB
      if (event.target.files[0].size > 10000000) {
        this.setAlert({ textArg: 'File size should be less than 10MB', variantArg: 'danger' });
        return;
      }
      [this.selectedFile] = event.target.files;
    },
    async readFileAsBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
          resolve(reader.result.split(',')[1]);
        };

        reader.onerror = (error) => {
          reject(error);
        };

        reader.readAsDataURL(file);
      });
    },
    async uploadFile(_type) {
      try {
        this.isLoading = true;
        this.removeAlert();
        const requestData = {
          type: 'document',
          title: '',
          file: null,
        };
        if (_type === 'document') {
          const data = await this.readFileAsBase64(this.selectedFile);
          requestData.file = data;
          requestData.title = this.selectedFile.name;
        } else {
          requestData.file = this.selectedVideo;
          requestData.title = '';
          requestData.type = _type;
        }
        const response = await MatchmakingService.offerUploadFile(this.formId, requestData);
        if (_type === 'video') {
          this.videos = response.data;
        } else {
          response.data.forEach((item) => {
            if (item.url.includes('pdf')) {
              if (this.docs.filter((doc) => doc.url === item.url).length === 0) {
                this.docs.push(item);
              }
            } else {
              /* eslint-disable no-lonely-if */
              if (this.images.filter((image) => image.url === item.url).length === 0) {
                this.images.push(item);
              }
            }
          });
        }
        this.selectedFile = null;
        this.selectedVideo = '';
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    async onRemoveFile(item) {
      try {
        this.isLoading = true;
        console.log(item, 'item');
        const requestData = {
          type: !item.type ? 'video' : 'document',
          file: item.url,
        };
        const response = await MatchmakingService.offerRemoveFile(this.form.id, requestData);
        if (!item.type) {
          this.videos = response.data;
          this.selectedVideo = '';
          this.form.video = '';
        } else {
          if (item.url.includes('pdf')) {
            this.docs = response.data.filter((doc) => doc.url.includes('pdf'));
          } else {
            this.images = response.data.filter((image) => !image.url.includes('pdf'));
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
    clearForm() {
      this.form = {
        id: 0,
        marketplace_id: this.marketplaceId,
        project_id: 0,
        delivery_at: '',
        price: 0,
        company_detail: '',
        scope_work: '',
        visible: 1,
        status: 6, // draft
      };
      this.selectedVideo = '';
      this.selectedFile = null;
      this.docs = [];
      this.videos = [];
      this.images = [];
      this.confirmDelete = false;
    },
    closeModal() {
      this.clearForm();
      this.$emit('close');
    },
    filterFormData(status = 6) {
      this.removeAlert();
      // if (!this.form.price) {
      //   this.setAlert({ textArg: 'Please enter price', variantArg: 'danger' });
      //   return;
      // }
      if (!this.form.delivery_at) {
        this.setAlert({ textArg: 'Please select delivery date', variantArg: 'danger' });
        return;
      }
      if (!this.form.company_detail) {
        this.setAlert({ textArg: 'Please enter company details', variantArg: 'danger' });
        return;
      }
      if (!this.form.scope_work) {
        this.setAlert({ textArg: 'Please enter scope of work', variantArg: 'danger' });
        return;
      }
      this.form.status = status;
      this.form.project_id = this.projectId;
      this.form.delivery_at = moment(this.form.delivery_at).format('DD.MM.YYYY');
      this.sendFormData();
    },
    async sendFormData() {
      try {
        this.isLoading = true;
        this.removeAlert();
        if (this.isNewOffer) {
          const { data } = await MatchmakingService.createOffer(this.form);
          this.setAlert({ textArg: 'Your offer is published', variantArg: 'success' });
          setTimeout(() => {
            this.$emit('onCreatedOffer', data);
          }, 1000);
        } else {
          const { data } = await MatchmakingService.updateOffer(this.offer.id, this.form);
          this.setAlert({ textArg: 'Your offer is updated', variantArg: 'success' });
          setTimeout(() => {
            this.$emit('onUpdateOffer', data);
          }, 1000);
        }
        this.clearForm();
      } catch (e) {
        console.log(e);
        if (e.response.data.message) {
          this.setAlert({ textArg: e.response.data.message, variantArg: 'danger' });
        } else {
          this.setAlert({ textArg: e.response.data[0].message, variantArg: 'danger' });
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
  mounted() {
    if (!this.isNewOffer) {
      this.form = {
        id: this.offer.id,
        marketplace_id: this.marketplaceId,
        project_id: this.offer.project.id,
        price: this.offer.price,
        company_detail: this.offer.company_detail,
        scope_work: this.offer.scope_work,
        visible: 1,
        status: this.offer.status,
      };
      this.videos = this.offer.video;
      this.offer.documents.forEach((item) => {
        if (item.url.includes('pdf')) {
          this.docs.push(item);
        } else {
          this.images.push(item);
        }
      });
      this.messages = this.offer.messages;
      const formattedDate = moment(this.offer.delivery_at.replace(' at ', ' '),
        'DD. MMM YYYY HH:mm').format('YYYY-MM-DD');
      this.form.delivery_at = formattedDate;
    }
  },
};
</script>

<style lang="scss" scoped>
.draft-note {
    a {
      text-decoration: underline;
    }
}
.error-wrapper {
  margin: 10px 0;
}
.pointer {
  cursor: pointer;
}
// .fr-2 {
//   grid-template-columns: repeat(2, 1fr);
// }

.make-offer-content {
  //max-height: 60vh;
  padding: 0 5px;
  //overflow: auto;
}

.make-offer-bottom {
  margin-top: 50px;
  display: flex;
  justify-content: flex-end;
}

.required::before {
  content: '*';
  color: red;
  margin-right: 4px;
}
</style>
